import {Network} from "../../../network/Network";
import {ProductType} from "../../../models/product/ProductType";
import {HttpStatus} from "../../../network/status/HttpStatus";
import {INetworkComponent} from "../../../network/types/INetworkComponent";
import {ICommunityKnowledgeModel} from "../../../models/community/knowledge/ICommunityKnowledgeModel";
import {
    ICommunityKnowledgeResourceCreatedIncoming
} from "../../../models/community/knowledge/ICommunityKnowledgeResourceCreatedIncoming";
import {
    ICommunityKnowledgeResourceIncoming
} from "../../../models/community/knowledge/ICommunityKnowledgeResourceOutgoig";
import {CommunityKnowledgeResourceMeshing} from "../../../models/community/knowledge/CommunityKnowledgeResourceMeshing";

export class CommunityKnowledgeService {

    public static dispose(): void {
    }

    public static init(): void {
    }

    public static async getByCommunity(communityId: string, component?: INetworkComponent): Promise<ICommunityKnowledgeModel[]> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/knowledge/${communityId}`, component);
        if (request.status === HttpStatus.OK) {
            return request.data;
        }
        return [];
    }

    public static async create(communityId: string, name: string, component?: INetworkComponent): Promise<ICommunityKnowledgeModel> {
        let request = await Network.post(ProductType.COMMUNITY, `/community/knowledge/create/${communityId}/${name}`, component);
        if (request.status === HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async update(communityId: string, knowledgeId: string, name: string, component?: INetworkComponent): Promise<ICommunityKnowledgeModel> {
        let request = await Network.get(ProductType.COMMUNITY, `/community/knowledge/update/${communityId}/${knowledgeId}/${name}`, component);
        if (request.status === HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async delete(communityId: string, knowledgeId: string, component?: INetworkComponent): Promise<ICommunityKnowledgeModel> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/knowledge/delete/${communityId}/${knowledgeId}`, component);
        if (request.status === HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async createResource(communityId: string, knowledgeId: string, resource: ICommunityKnowledgeResourceIncoming, component?: INetworkComponent): Promise<ICommunityKnowledgeResourceCreatedIncoming> {
        let request = await Network.postJson(ProductType.COMMUNITY, `/community/knowledge/create/resource/${communityId}/${knowledgeId}`, resource, component);
        if (request.status === HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }

    public static async createResourceFile(communityId: string, knowledgeId: string, meshing: CommunityKnowledgeResourceMeshing, file: File, component?: INetworkComponent): Promise<ICommunityKnowledgeResourceCreatedIncoming> {
        let request = await Network.postFormData(ProductType.COMMUNITY, `/community/knowledge/create/resource/file/${communityId}/${meshing}/${knowledgeId}`, {
            file: file,
        }, component);

        if (request.status == HttpStatus.OK) {
            return request.data;
        }

        return undefined;
    }

    public static async deleteResource(communityId: string, knowledgeId: string, resourceId: string, component?: INetworkComponent): Promise<ICommunityKnowledgeModel> {
        let request = await Network.delete(ProductType.COMMUNITY, `/community/knowledge/delete/${communityId}/${knowledgeId}/${resourceId}`, component);
        if (request.status === HttpStatus.OK) {
            return request.data;
        }
        return undefined;
    }
}
