import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {PageComponent} from "./types/PageComponent";
import {SedestralInterface} from "../../sedestral-interface-component/interface/SedestralInterface";
import {SedestralMachine} from "../../sedestral-interface-component/machine/SedestralMachine";
import {SedestralRouter} from "../SedestralRouter";
import {SedestralSsr} from "../../sedestral-interface-component/ssr/SedestralSsr";
import {Resources} from "../../../resources/Resources";
import {config} from "../../../config";
import {Network} from "../../../network/Network";
import {ProductName} from "../../../models/product/ProductName";
import {ProductType} from "../../../models/product/ProductType";
import {CommunityService} from "../../../services/community/CommunityService";
import {CommunityPublishState} from "../../../models/enums/CommunityPublishState";
import {IFileModel} from "../../../models/file/IFileModel";
import {ICommunityModel} from "../../../models/community/ICommunityModel";
import {SedestralPageStats} from "./stats/SedestralPageStats";
import {SedestralDebugger} from "../../sedestral-interface-component/debugger/SedestralDebugger";
import {elementParents} from "../../sedestral-interface-component/utilities/ElementParents";
import {isMobile} from "../../sedestral-interface-component/utilities/IsMobile";

export class SedestralPageRouter {
    public router: SedestralRouter;
    public stats: SedestralPageStats;
    public main: Component;

    public page: PageComponent;
    public pagePath: string;
    public pages: object;
    public pagesInitialized: object;

    public defaultLanguage;
    public languages;

    public noOnHash: boolean;
    public change: boolean;
    public onReload: boolean;

    public pathLogin: string;
    public pathHome: string;
    public path404: string;

    public dynamicPath: string;

    public popStateFunctions: (() => void)[];

    public bufferHistory: any;
    public bufferHistoryPath: string;

    constructor(router: SedestralRouter, languages?: string[], defaultLanguage?: string) {

        this.router = router;
        this.languages = languages ?? [Resources.getDefaultLanguage()];
        this.defaultLanguage = defaultLanguage ?? Resources.getDefaultLanguage();
        this.main = SedestralInterface.main;
        this.pages = {};
        this.pagesInitialized = {};
        this.page = undefined;
        this.change = false;
        this.popStateFunctions = [];

        this.stats = new SedestralPageStats(this);
    }

    /**
     * init
     */

    public init() {
        this.main.clear();

        this.intAHref();
        this.initPopState();
    }

    public intAHref() {
        let globalFunc = (e) => {
            let parents = elementParents(e.target) as HTMLElement[];
            let elementA = e.target.tagName == "A" ? e.target : (parents).find(value => value.tagName == "A");
            if (elementA) {
                let href = elementA.getAttribute("href");
                let target = elementA.getAttribute("target");
                let download = elementA.getAttribute("download");

                if (target !== "_blank" && download == undefined) {
                    try {

                        let url = new URL(href);

                        let x;
                        if (process.env.PRODUCT != "blog" && process.env.PRODUCT != "helpcenter") {
                            if (url.origin != process.env.APP_URL) {
                                window.open(url);
                            }
                        }

                        href = href.replace(url.origin, "");
                        if (href == "") {
                            href = this.pathLogin;
                        }
                    } catch (ignored) {
                    }

                    e.preventDefault();

                    if (elementA.getAttribute("click") == undefined && elementA.getAttribute("data-click") == undefined) {
                        this.setPath(href);
                    }
                }
            }
        };

        SedestralMachine.addListener(document, 'click', globalFunc, true);
    }

    public initPopState() {
        window.onpopstate = async () => {
            await this.execute(this.getPath());
        };

        this.setPath(this.getPath());
    }

    /**
     * pages
     */
    public async getPage(path: string): Promise<PageComponent> {
        path = path.startsWith("/") ? path.substring(1) : path;
        const segments = path.split("/");
        let page;
        for (let i = segments.length; i > 0; i--) {
            const partialPath = "/" + segments.slice(0, i).join("/");
            if (this.pages[partialPath] !== undefined) {
                page = this.pagesInitialized[partialPath];
                if (page == undefined) {
                    this.pagePath = partialPath;
                    page = await this.pages[partialPath](partialPath);
                    this.pagesInitialized[partialPath] = page;
                }
                break;
            }
        }

        return page;
    }

    public registerPage(name: string, component: () => void, componentMobile?: () => void) {
        this.pages[name] = isMobile() && componentMobile ? componentMobile : component;
    }

    // @ts-ignore
    public async getDynamicPage(path: string): Promise<PageComponent> {

    }

    /**
     * language
     */

    public pathIsLanguage(path: string): boolean {
        return this.languages.includes(path);
    }

    public pathHasLanguage(path: string): boolean {
        let firstPath = path.split("/").filter(value => value != "")[0];
        return this.pathIsLanguage(firstPath);
    }

    public removeLanguageFromPath(path: string): string {
        if (this.pathHasLanguage(path)) {
            let language = this.getLanguageFromPath(path);
            if (language) {
                path = path.replace(`/${language}`, "");
            }
        }

        return path;
    }

    getLanguageFromPath(path: string): string {
        let firstPath = path.split("/").filter(value => value != "")[0];
        return this.pathIsLanguage(firstPath) ? firstPath : undefined;
    }

    addLanguageToPath(language: string, path: string): string {
        return `/${language}` + path;
    }

    getLanguage(): string {
        let language = this.getLanguageFromPath(this.getPath());
        if (language && this.pathIsLanguage(language)) {
            return language;
        }

        return this.defaultLanguage;
    }

    setLanguage(language: string) {
        this.setPath(`/${language}${this.getPathWithoutLanguage()}`);
        window.location.reload();
    }

    /**
     * hash
     */

    /**
     * Récupérer le hash actuel, sinon le hash de login si vide
     */

    public getPath() {
        let path = window.location.pathname;
        if (path == "/" || this.pathIsLanguage(path.replace("/", ""))) {
            path = this.pathHome;
        }

        return path;
    }

    public getPathWithoutLanguage() {
        return this.removeLanguageFromPath(this.getPath());
    }

    public async setPath(path: string): Promise<void> {
        if (!this.pathHasLanguage(path)) {
            path = this.addLanguageToPath(this.getLanguage(), path);
        }

        if (path != this.getPath()) {
            if (!this.bufferHistoryPath) {
                this.bufferHistoryPath = this.getPath();
            }
            clearTimeout(this.bufferHistory);
            history.replaceState({}, '', path);
            this.bufferHistory = setTimeout(() => {
                history.replaceState({}, '', this.bufferHistoryPath);
                history.pushState({}, '', path);
                this.bufferHistoryPath = undefined;
            }, 500);
        }

        await this.execute(path);
    }

    public async changePath(path: string) {
        let p = this.removeLanguageFromPath(path).substring(1);
        p = "/" + p.split("/")[0];
        let page = this.pagesInitialized[p];
        if (!page) {
            page = this.page;
        }

        if (page.constructor.name == this.page.constructor.name) {
            this.change = true;
            await this.setPath(path);
        } else {
            await this.setPath(path);
        }
    }


    /**
     * redirection
     */
    public async redirect(path: string, newTab?: boolean) {
        if (newTab) {
            window.open(path);
        } else {
            path = path == "" || path == "/" ? this.pathHome : path;
            await this.setPath(path);
            window.scroll(0, 0);
        }
    }

    public async reload(path?: string) {
        this.onReload = true;
        await this.redirect(path ?? this.getPath());
    }

    /**
     * params
     */

    public getParams(): string[] {
        return (this.dynamicPath ? this.dynamicPath : this.getPath()).split("/");
    }

    public getParam(name: string): string {
        let hashes = this.getParams();
        for (let i = 0; i < hashes.length; i++) {
            if (hashes[i] == name) {
                if (typeof hashes[i + 1] !== "undefined") {
                    return hashes[i + 1];
                }
            }
        }
        return undefined;
    }

    public getParamAtIndex(index: number) {
        return this.getParams()[index];
    }

    public hasParam(name: string): boolean {
        let hashes = this.getPath().split("/");
        for (let i = 0; i < hashes.length; i++) {
            if (hashes[i] == name) {
                return true;
            }
        }
        return false;
    }

    public removeParams(names: string[], noHash?: boolean): void {
        names.forEach(value => this.removeParam(value, noHash));
    }

    public removeParam(name: string, noHash?: boolean, forceBase?: boolean): void {
        if (this.hasParam(name)) {
            this.noOnHash = noHash;

            let currentParam = this.getParam(name);
            let hash = this.getPath().replace("/" + name + "/" + currentParam, "");

            if (forceBase)
                hash = hash.replace("/" + name, "");

            this.changePath(hash);
        }
    }

    removeParamAfter(paramName: string) {
        let params = Network.router.pages.getParams();
        for(let i = 0; i < params.length; i++){
            let baseIndex = params.indexOf(paramName);
            if (baseIndex >= 0) {
                if (i > baseIndex) {
                    if (params[i].length > 0) {
                        Network.router.pages.removeParam(params[i], true);
                    }
                }
            }
        }
    }

    public setParams(params: { name: string, value: string | number }[], noHash?: boolean): void {
        let hash = this.getPath();
        if (noHash) {
            this.noOnHash = true;
        }

        params.forEach((param) => {
            if (param.value)
                hash = this.setParamUrl(hash, param.name, param.value, true);
            else {
                hash = this.removeParamStr(hash, param.name);
            }
        });

        this.changePath(hash);
    }

    public setParam(name: string, value: string | number, preserve?: boolean, noHash?: boolean): void {
        if (noHash) {
            this.noOnHash = true;
        }

        this.changePath(this.setParamUrl(this.getPath(), name, value, preserve));
    }

    public setParamStr(path: string, name: string, value: string | number): string {
        const regex = new RegExp(`/${name}/[^/]+`);
        if (path.match(regex)) {
            return path.replace(regex, `/${name}/${value}`);
        } else {
            return `${path}/${name}/${value}`;
        }
    }

    public removeParamStr(path: string, name: string): string {
        const regex = new RegExp(`/${name}/[^/]+`);
        return path.replace(regex, '');
    }

    public hasParamStr(path: string, name: string): boolean {
        const regex = new RegExp(`/${name}/[^/]+`);
        return regex.test(path);
    }


    /**
     * funcs
     */


    public onPopState(component: Component, func: () => void) {
        this.popStateFunctions.push(func);
        if (component) {
            component.onRemove(() => this.popStateFunctions.splice(this.popStateFunctions.indexOf(func), 1));
        }
    }

    /**
     * run
     */
    public async execute(path: string) {
        this.dynamicPath = undefined;

        if (!this.noOnHash) {
            path = this.removeLanguageFromPath(path);

            let page = await this.getPage(path);
            if (page == undefined) {
                page = await this.getDynamicPage(path);
                if (page == undefined) {
                    return this.redirect(this.path404);
                }
            }
            if (!page.status.open) {
                if (page.status.open != undefined && page.status.online == true && !this.isOnline()) {
                    return this.redirect(this.pathLogin);
                }

                if (path != this.path404 && page.status.online != undefined && page.status.online == false && this.isOnline()) {
                    return this.redirect(this.pathHome);
                }
            }

            if (!this.change) {
                if (!this.onReload && page == this.page) {
                    page.onHash();
                    this.router.static.onHash();
                } else {
                    if (this.page !== undefined) {
                        this.page.remove();
                        SedestralInterface.main.clear();
                    }

                    this.page = page;
                    this.renderPage(page);
                    page.onHash();

                    this.router.static.onHash();
                    this.updatePageMeta(page);
                }
            } else {
                if (this.onReload || page != this.page) {
                    if (this.page != undefined) {
                        this.page.remove();
                        SedestralInterface.main.clear();
                    }

                    this.page = page;
                    this.renderPage(page);
                    this.updatePageMeta(page);
                }

                page.onHash();
                this.router.static.onHash();
            }
        }

        if (!this.noOnHash) {
            this.popStateFunctions.forEach(value => value());
        }

        this.onReload = false;
        this.change = false;
        this.noOnHash = false;
    }

    public renderPage(page: PageComponent) {
        let rum = SedestralDebugger.rum?.pageLoad();
        SedestralInterface.main.render(page);
        SedestralSsr.awaitProcessing().then(() => {
            rum?.end();
        });
        window.scroll(0, 0);
    }

    /**
     * logic
     */
    public updatePageMeta(page: PageComponent) {
        document.documentElement.lang = Resources.language;
        document.title = page.title;

        this.updateMeta("robots", this.getRobots(page));
        this.updateMeta("og:locale", Resources.language);
        this.updateMeta("og:title", page.title);
        this.updateMeta("twitter:title", page.title);
        this.updateMeta("name", page.title);
        this.updateMeta("description", page.description);
        this.updateMeta("og:description", page.description);
        this.updateMeta("twitter:description", page.description);
        this.updateMeta("keywords", page.keywords);
        this.updateMeta("image", page.image);
        this.updateMeta("og:image", page.image);
        this.updateMeta("twitter:image", page.image);
        this.updateMeta("og:url", page.ogUrl);
        this.updateMeta("og:site_name", page.ogSiteName);
        this.updateMeta("og:type", page.ogType);
        this.updateAlternateLinks(page.alternateLang);
        this.updateFavIcon(page.favicon);
    }

    updateMetasCommunity(community: ICommunityModel, isArticle: boolean, title?: string, description?: string, keywords?: string[], image?: IFileModel, alternateLang?: Map<string, string>) {
        this.page.title = title ? title : (community.text.metaTitle ?? Resources.t("words.metas.title"))
        this.page.ogSiteName = community.text.metaTitle ?? Resources.t("words.metas.title");
        this.page.description = description ? description : community.text.metaDescription;
        this.page.keywords = keywords ? keywords.join(", ") : community.text.metaKeywords.join(", ");
        this.page.image = image ? image.link : community.logoFile?.link ?? undefined;
        this.page.ogUrl = this.getCurrentUrlWithLang();
        this.page.favicon = community?.faviconFile?.link;
        this.page.ogType = isArticle ? "article" : "website";

        if (!alternateLang) {
            alternateLang = new Map<string, string>();
            community.languages.forEach(lang => {
                alternateLang.set(lang, this.getCurrentUrlWithLang(lang));
            });
        }

        this.page.alternateLang = alternateLang;
        this.updatePageMeta(this.page);
    }

    public updateMeta(name: string, value?: string) {
        const metas = document.querySelectorAll(`head meta[name="${name}"], head meta[itemprop="${name}"], head meta[property="${name}"]`);
        metas.forEach(meta => {
            meta.setAttribute('content', value ?? "");
        });
    }

    public updateFavIcon(icon = Network.vendor + "images/favicon.png") {
        let link = document.querySelector('link[rel="icon"]');
        if (link) {
            link.setAttribute('href', icon);
        }
    }

    public getUrlWithLang(lang?: string) {
        return config.domain + "/" + (lang ?? Resources.language) + Network.router.pages.pagePath;
    }

    public getCurrentUrlWithLang(lang?: string) {
        return config.domain + "/" + (lang ?? Resources.language) + location.pathname.replace(/^\/[a-z]{2}/i, '');
    }

    public getUrlWithPath(path: string) {
        return config.domain + "/" + Resources.language + "/" + path;
    }

    public getPanelUrl(path?: string) {
        return this.generateUrl(process.env.PANEL_URL, path);
    }

    public getWebsiteUrl(path?: string) {
        return this.generateUrl(process.env.WEBSITE_URL, path);
    }

    public updateAlternateLinks(alternateLanguages: Map<string, string>): void {
        // Supprimer tous les liens alternatifs existants
        document.querySelectorAll<HTMLLinkElement>('link[rel="alternate"]').forEach(link => {
            link.parentNode?.removeChild(link);
        });

        // Ajouter les nouveaux liens alternatifs
        alternateLanguages.forEach((url, lang) => {
            this.updateAlternateLink(lang, url);
        });

        //Si aucun lien alternate, alors on place quand même l'url actuelle
        if (alternateLanguages.size == 0) {
            this.updateAlternateLink(Resources.language, window.location.pathname);
        }
    }

    /**
     * override
     */

    public isOnline(): boolean | void {
        return false;
    }

    public isNoneSite(): boolean {
        return false;
    }

    private setParamUrl(hash: string, name: string, value: string | number, preserve?: boolean): string {
        let finalHash;

        if (this.hasParam(name)) {

            let currentParam = this.getParam(name);

            let toReplace = name + (currentParam == undefined ? "" : "/" + currentParam);
            let replacement = name + "/" + value;
            finalHash = hash.replace(toReplace, replacement);

            let afters = finalHash.split(replacement);
            if (afters.length > 0) {
                if (!preserve) {
                    finalHash = finalHash.replace(afters[1], "");
                }
            }
        } else {
            finalHash = hash + "/" + name + "/" + value;
        }

        return finalHash;
    }

    private getRobots(page: PageComponent) {
        let noIndex = [
            "/helpcenter",
            "/service-client",
            "/inbox",
            "/mail",
            "/publisher",
            "/statistics",
            "/livechat"
        ];

        if (noIndex.includes(location.pathname.replace(/^\/[a-z]{2}/i, ''))) {
            return "noindex";
        }

        if (!config.isProd) {
            return "noindex";
        }

        if (Network.productName == ProductName.toString(ProductType.HELPCENTER)
            || Network.productName == ProductName.toString(ProductType.BLOG)) {

            const isExternalCommunity = CommunityService?.live?.community.publishState === CommunityPublishState.EXTERNAL;
            const isHostCustomVerified = CommunityService?.live?.community.hostCustomVerified;
            const isHostCustomMatching = ("https://" + CommunityService?.live?.community.hostCustom) === config.domain;

            return isExternalCommunity && (!isHostCustomVerified || isHostCustomMatching) ? "index" : "noindex";

        } else if (Network.productName == ProductName.toString(ProductType.WEBSITE)) {
            return "index";
        } else {
            return SedestralSsr.hasSsr() || this.hasPathIndex() ? "index" : "noindex";
        }
    }

    private hasPathIndex() {
        const pathsToCheck = ["login", "signup", "password"];
        return pathsToCheck.some(path => window.location.pathname?.includes(path));
    }

    private updateAlternateLink(lang: string, url: string): void {
        if (!url.startsWith('http')) {
            url = config.domain + url;
        }
        let link = document.createElement('link');
        link.rel = 'alternate';
        link.hreflang = lang;
        link.href = url;
        document.head.appendChild(link);
    }

    private generateUrl(url: string, path?: string) {
        if (path) {
            return url + "/" + Resources.language + "/" + path;
        } else {
            return url + "/" + Resources.language;
        }
    }
}