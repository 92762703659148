import {Network} from "../network/Network";
import {config} from "../config";
import {
    DateIntervalPickerPurposes
} from "../sedestral-interface-modules/sedestral-interface-component-global/date/interval/picker/types/DateIntervalPickerPurposes";
import {AppearanceTheme} from "../models/enums/AppearanceTheme";
import {SedestralStorage} from "../sedestral-interface-modules/sedestral-interface-component/memory/SedestralStorage";
import {
    SedestralInterface
} from "../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";
import {txtSimpleLang} from "../sedestral-interface-modules/sedestral-interface-component/utilities/TxtSimpleLang";
import {generateVendor} from "../sedestral-interface-modules/sedestral-interface-component/utilities/GenerateVendor";
import {ProductType} from "../models/product/ProductType";
import {ProductName} from "../models/product/ProductName";


export class Resources {
    public static countries: any[];
    public static emojis: any[];
    public static worldLanguages: { code: string, name: string }[];

    public static words: any;

    public static languages: string[];
    public static language: string;

    private static languageToCountryMap = {
        "fr": "fr",  // France
        "en": "en",  // United Kingdom (commonly used for English)
        "es": "es",  // Spain
        "it": "it",  // Italy
        "pt": "pt",  // Portugal
        "nl": "nl",  // Netherlands
        "de": "de",  // Germany
        "lb": "lu",  // Luxembourg
        "da": "dk"   // Denmark
    };

    static getCountryCode() {
        return this.languageToCountryMap[this.language] || "fr";
    }

    public static async init(languagesCandidate: string[], languages: string[], defaultLanguage = this.getDefaultLanguage()): Promise<void> {
        this.languages = languages;

        let selectBestLanguageCandidate = this.getBestLanguageCandidate(languages, languagesCandidate);

        this.setLanguage(selectBestLanguageCandidate);

        if (!this.language) {
            this.setLanguage(defaultLanguage);
        }

        if (!this.language) {
            this.setLanguage(this.getDefaultLanguage());
        }

        if (config.product == "blog")
            this.theme(ProductType.BLOG) == AppearanceTheme.LIGHT ? SedestralInterface.html.removeClass("black") : SedestralInterface.html.addClass("black");
    }

    public static async loadI18n(product: string, vendor: string = generateVendor(window.location.origin)): Promise<void> {
        const request = await Network.httpResources.get(vendor + "languages/" + product + "/" + this.language + ".json?" + config.version, {timeout: 60000});

        if (this.words !== undefined) {
            this.words = this.mergeI18n(this.words, request.data);
        } else {
            this.words = request.data;

            Network.initErrors();
            DateIntervalPickerPurposes.init();
        }
    }

    public static async loadCountries(vendor: string = generateVendor(window.location.origin)): Promise<void> {
        let request = await Network.httpResources.get(vendor + "countries/" + this.language + ".json?" + config.version, {timeout: 60000});
        this.countries = request.data;
    }

    public static async loadEmojis(vendor: string = generateVendor(window.location.origin)): Promise<any> {
        if (this.emojis != undefined) {
            return this.emojis;
        }

        let request = await Network.httpResources.get(vendor + "emojis/emojis.json?" + config.version);
        this.emojis = request.data;
        return this.emojis;
    }

    public static async loadWorldLanguages(vendor: string = generateVendor(window.location.origin)): Promise<void> {
        let request = await Network.httpResources.get(vendor + "languages/names.json?" + config.version, {timeout: 60000});
        this.worldLanguages = request.data;
    }

    public static t(value, words?: any) {
        const values = value.split(".");
        let object = words ?? this.words;

        for (const val of values) {
            if (object) {
                object = object[val];
            }
        }

        return object ?? value;
    }

    public static r(value, replacements, words = this.words) {
        const values = value.split(".");
        let object = words;

        for (const val of values) {
            if (typeof object !== "undefined") {
                object = object[val];
            }
        }

        let result = (typeof object === "undefined" || typeof object === "object") ? value : object;

        for (const key in replacements) {
            result = result.replace(`\${${key}}`, replacements[key]);
        }

        return result;
    }

    public static exist(value, words?: any): boolean {
        const values = value.split(".");
        let object = words ?? this.words;

        for (const val of values) {
            if (object) {
                object = object[val];
            }
        }

        return object !== undefined;
    }

    public static country(a2: string): { id: number, n: string, a2: string, a3: string } {
        a2 = a2.toLowerCase();
        return this.countries.filter(value => value['a2'] == a2)[0];
    }

    public static lang(code: string): { code: string, name: string } {
        code = txtSimpleLang(code);
        for (let key in this.worldLanguages) {
            if (this.worldLanguages.hasOwnProperty(key)) {
                if (this.worldLanguages[key].code == code) {
                    return this.worldLanguages[key];
                }
            }
        }
    }

    public static getLanguagesCandidate(): string[] {
        return [...new Set(navigator.languages.map(language => this.removeRegionLanguage(language)))];
    }

    public static getLanguagesCandidateWidthDefault(bestLanguageCandidate: string): string[] {
        return [...new Set([Resources.removeRegionLanguage(bestLanguageCandidate), ...Resources.getLanguagesCandidate()]
            .filter(language => language !== undefined))];
    }

    public static getBestLanguageCandidate(languages: string[], languagesCandidate: string[] = this.getLanguagesCandidate()) {
        return languagesCandidate.find(language => languages.includes(language));
    }

    public static getDefaultLanguage() {
        return "en";
    }

    private static setLanguage(language) {
        if (this.languages.includes(language)) {
            this.language = language;
        }
    }

    private static removeRegionLanguage(language: string): string {
        if (language == undefined) {
            return undefined;
        }

        const withoutQuality: string = language.split(";")[0];

        if (withoutQuality.includes("-")) {
            return withoutQuality.split("-")[0];
        }
        if (withoutQuality.includes("_")) {
            return withoutQuality.split("_")[0];
        }

        return withoutQuality;
    }

    public static theme(productType: ProductType): AppearanceTheme {
        let theme = this.realTheme(productType);
        if (theme == AppearanceTheme.AUTO) {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                return AppearanceTheme.DARK;
            } else {
                return AppearanceTheme.LIGHT;
            }
        }

        return theme;
    };

    public static realTheme(productType: ProductType): AppearanceTheme {
        let theme = SedestralStorage.getItem(`${ProductName.toString(productType)}-theme`) as any;
        if (theme == null) {
            return AppearanceTheme.AUTO;
        }

        return parseInt(theme);
    }

    public static invertTheme(productType: ProductType, theme?: AppearanceTheme): AppearanceTheme {
        return (theme ? theme : Resources.theme(productType)) == AppearanceTheme.DARK ? AppearanceTheme.LIGHT : AppearanceTheme.DARK;
    }

    public static setTheme(productType: ProductType, theme: AppearanceTheme, silent?: boolean) {
        SedestralStorage.setItem(`${ProductName.toString(productType)}-theme`, "" + theme);
        if (!silent)
            this.theme(productType) == AppearanceTheme.LIGHT ? SedestralInterface.html.removeClass("black") : SedestralInterface.html.addClass("black");
    }

    private static mergeI18n(wordsA: any, wordsB: any): any {
        return {
            ...wordsA,
            ...wordsB,
            words: {...wordsA.words, ...wordsB.words},
            network: {...wordsA.network, ...wordsB.network},
            errors: {...wordsA.errors, ...wordsB.errors},
            placeholders: {...wordsA.placeholders, ...wordsB.placeholders},
            sentences: {...wordsA.sentences, ...wordsB.sentences},
            products: {...wordsA.products, ...wordsB.products},
            time: {...wordsA.time, ...wordsB.time}
        };
    }
}